import { Button, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from '@mui/material';
import { ArrowrightRed, NotificationIcon, ZupotsuColuredLogo, Athlete, editIcon, DocumentIcon, SmsIcon, SMSTracking } from '../../assets';
import { memo, useCallback, useEffect, useState } from 'react';
import useDeviceType from '../../utils/DeviceType';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import Logout from '../../Atoms/logout/Logout';
import MyProposals from './MyProposals';
import { Dispatch, SetStateAction } from 'react';
import { Close } from '@mui/icons-material';
import { Modal, Typography } from '@mui/material';
import ZupotsuButton from '../../Atoms/zupotsu-button/zupotsu-button';
export interface HeaderProps {
    sidebarOpen?: boolean;
    toggleSidebar?: () => void;
    isUserLogin: boolean;
    loginButtonClicked?: () => void;
    showNotificationIcon: boolean;
    onNotificationIconClicked: () => void;
    logoutButtonClicked?: () => void;
    profileShow: boolean;
    setShowProfile: Dispatch<SetStateAction<any>>;
}

export function Header(props: HeaderProps) {
    const deviceType = useDeviceType();
    const navigate = useNavigate();
    const location: any = useLocation();
    const name: any = localStorage.getItem("name") || "";
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [logoutpop, setLogoutPopup] = useState(false)
    const [sel, setSel] = useState("catalog")
    const [profileShow, setShowProfile] = useState<any>(false)
    const navigation = useNavigate()
    const handleClick = (event: any) => {setAnchorEl(event.currentTarget)};
    const userFromLocal = localStorage.getItem("role")?.toLowerCase();
    const isSeller = (userFromLocal === "seller") ? true : false;
    const isSellerAdmin = (userFromLocal === "seller-admin") ? true : false;
    const isBuyer = (userFromLocal === "buyer") ? true : false;
    const handleClose = () => {setAnchorEl(null);};
    const ZupotsuUsers = (userFromLocal === "admin" || userFromLocal === "publisher" || userFromLocal === "approver") ? true : false;
    const [searchParams] = useSearchParams();
    const isCatalogue = searchParams.get('screen')=="catalogue"?true:false;
    const queryParams = new URLSearchParams(location.search);
    const screenParam = queryParams.get('screen');

    useEffect(() => {
        if (location.pathname == "/catalogue") {
            setSel("catalog")
        } else if (location.pathname == "/catalogue-management") {
            setSel("myassets")
        }
    }, [location])
    
    const style:any = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        backgroundColor: "#FFF",
        border: '0px solid #000',
        borderRadius: '8px',
        divShadow: 24,
        padding: "20px",
        fontFamily: 'Inter'
    };


    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: deviceType == "mobile" ? '5px 20px' : '5px 40px',
                borderBottom: '1px solid #E0E0E0',
                backgroundColor: '#FFF',
                height: "9vh"
            }}
        >

            {(!props.sidebarOpen) && (<div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '-10px'
            }}>

                {!(ZupotsuUsers && (location.pathname == "/catalogue"|| location.pathname == "/catalogue/tray" || (location.pathname == "/assetDetails" &&  screenParam === "catalogue")))&&(<button onClick={props.toggleSidebar} style={{ border: "0px solid rgba(0,0,0,0.2)", backgroundColor: "rgba(0,0,0,0.01)", borderRadius: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center", height: '30px', }}>
                    <MenuIcon sx={{ color: "red", fontSize: "30px" }} />
                </button>)}

                <img src={ZupotsuColuredLogo} style={{
                    width: "100px",
                    height: "80px",
                    paddingTop: "5px"
                }} />
            </div>)}

            {(props.sidebarOpen && (location.pathname == "/catalogue" || location.pathname.includes("/catalogue/tray") || isSeller || isSellerAdmin || isBuyer || isCatalogue)) && (<div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '-5px'
            }}>

                {deviceType == "mobile" && (<button onClick={props.toggleSidebar} style={{ border: "0px solid rgba(0,0,0,0.2)", backgroundColor: "rgba(0,0,0,0.01)", borderRadius: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center", height: '30px', }}>
                    <MenuIcon sx={{ color: "red", fontSize: "30px" }} />
                </button>)}

                <img src={ZupotsuColuredLogo} style={{
                    width: "100px",
                    height: "80px",
                    paddingTop: "5px"
                }} />
            </div>)}

            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                alignItems: 'center',
            }}>
                {/* ||(deviceType != "mobile" &&ZupotsuUsers && location.pathname == "/catalogue") */}
                {((deviceType != "mobile" && !isBuyer && !ZupotsuUsers) ) && (<div onClick={() => { setSel('myassets'); navigate("/catalogue-management") }}
                    style={{ paddingBottom: '10px', borderBottom: sel == "myassets" ? '2px solid rgba(226, 11, 24, 1)' : 'none', height: '30px', fontFamily: "Inter", fontSize: "16px", fontWeight: 500, lineHeight: "28px", textAlign: "left", color: sel == "myassets" ? 'rgba(226, 11, 24, 1)' : '#333333', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', cursor: 'pointer', marginTop: '10px' }}
                >
                    My Assets
                </div>)}

                {((deviceType !== "mobile" && !ZupotsuUsers)) && (
                    <div 
                        onClick={() => {
                            // if(location.pathname !== "/catalogue" ) {
                            //     setSel('myproposals');
                            // }
                            // if(isBuyer) {
                            //     setSel('myproposals');
                            //     navigate("/newrequests");
                            // }
                            alert("Coming Soon!!")
                        }}
                        style={{
                            paddingBottom: '10px', 
                            borderBottom: sel === "myproposals" ? '2px solid rgba(226, 11, 24, 1)' : 'none', 
                            height: '30px', 
                            fontFamily: "Inter", 
                            fontSize: "16px", 
                            fontWeight: 500, 
                            lineHeight: "28px", 
                            textAlign: "left", 
                            color: sel === "myproposals" ? 'rgba(226, 11, 24, 1)' : '#333333', 
                            display: 'flex', 
                            flexDirection: 'row', 
                            justifyContent: 'space-evenly', 
                            alignItems: 'center', 
                            marginLeft: '30px', 
                            cursor: 'pointer', 
                            marginTop: '10px'
                        }}
                    >
                        {/* {!isBuyer && (<MyProposals />)} 
                        {isBuyer && "My Proposals"}  */}

                    My Proposals
                    </div>
                )}

                {((!ZupotsuUsers) || (ZupotsuUsers && location.pathname == "/catalogue")) &&  (
                    <div onClick={() => 
                    { 
                        setSel('catalog'); 
                        if(location.pathname == "/catalogue"){}
                        else if(isCatalogue){navigate("/catalogue")}
                        else if(isBuyer || isSeller || isSellerAdmin){navigate("/catalogue")}
                        else {window.open("/catalogue", "_blank")}
                    }}
                        style={{ paddingBottom: '10px', borderBottom: sel == "catalog" ? '2px solid rgba(226, 11, 24, 1)' : 'none', height: '30px', fontFamily: "Inter", fontSize: "16px", fontWeight: 500, lineHeight: "28px", textAlign: "left", color: sel == "catalog" ? 'rgba(226, 11, 24, 1)' : '#333333', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginLeft: '30px', cursor: 'pointer', marginTop: '10px' }}
                    >
                        Catalogue
                    </div>
                )}

                {((ZupotsuUsers && location.pathname != "/catalogue")) && (<div
                    onClick={() => 
                    {
                        if(location.pathname == "/catalogue"){}
                        else if(isCatalogue){navigate("/catalogue")}
                        else {window.open("/catalogue", "_blank")}
                    }}
                    style={{
                        padding: " 16px 14px", borderRadius: "5px", backgroundColor: '#fff',  border: '1px solid rgba(226, 11, 24, 1)', gap: '4px', height: '30px', fontFamily: "Inter", fontSize: deviceType=="mobile"?"13px":"16px", fontWeight: 500, lineHeight: "28px", textAlign: "left", color: 'rgba(226, 11, 24, 1)', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginLeft: '10px', cursor: 'pointer'
                    }}
                >
                    {(isCatalogue&&location.pathname.includes("assetDetails"))?"Back to Catalogue":"Catalogue"}
                    <img src={ArrowrightRed} style={{ width: '24px', height: "24px",transform:(isCatalogue&&location.pathname.includes("assetDetails"))?'rotate(220deg)':'' }} />

                </div>)}
                {/* {deviceType != "mobile" && ( */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                            onClick={handleClick}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                // border:'2px solid #E10B17',
                                borderRadius: 100,
                                marginLeft: deviceType != "mobile" ?'30px':'15px',
                                background: '#F2F2F2',
                                width: '40px',
                                height: '40px',
                                cursor: 'pointer'
                            }}
                        >
                            {/* <img
                            src={Athlete}
                            width={25}
                            height={25}
                            onClick={()=>{}}
                        /> */}
                            <span style={{
                                fontFamily: "Inter",
                                fontSize: "20px",
                                fontWeight: 500,
                                lineHeight: "28px",
                                // marginLeft: '5px'
                            }}>{name.charAt(0).toUpperCase()}</span>
                        </div>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            sx={{
                                boxShadow: "4px 4px 12px 0px rgba(0, 0, 0, 0.07)",
                                borderRadius: '10px',
                            }}
                        >
                            <MenuList
                                sx={{
                                    outline: 'none',
                                    borderRadius: '10px'
                                }}
                            >
                                {
                                    [
                                        {
                                            key: 1,
                                            name: "Profile",
                                            disabled: false,
                                        },

                                        {
                                            key: 2,
                                            name: "Logout",
                                            disabled: false,
                                        }
                                    ].map((menudata) => !menudata.disabled && (
                                        <MenuItem
                                            key={menudata.key}
                                            onClick={() => {
                                                if (menudata.name == "Logout") {
                                                    setLogoutPopup(true)
                                                }
                                                else if (menudata.name == "Profile") {
                                                    // setShowProfile(true)
                                                    navigation('/profile')
                                                }
                                                handleClose(); // Close menu on click
                                            }}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                '&:hover': {
                                                    background: 'rgba(226, 11, 24, 0.2)',
                                                },
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {/* {menudata.icon && (
                                <ListItemIcon
                                sx={{
                                    width: '20px',
                                    height: '20px',
                                    minHeight: '20px',
                                    minWidth: '20px !important',
                                }}
                                >
                                <img src={menudata.icon} alt={menudata.name} />
                                </ListItemIcon>
                            )} */}
                                                <ListItemText
                                                    sx={{
                                                        fontSize: '10px',
                                                        fontWeight: 500,
                                                        color: '#333',
                                                        fontFamily: "Inter",
                                                        lineHeight: "21px",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {menudata.name}
                                                </ListItemText>
                                            </div>
                                        </MenuItem>
                                    ))
                                }
                            </MenuList>
                        </Menu>
                        {deviceType != "mobile" &&(<span onClick={handleClick} style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: 500, lineHeight: "28px", marginLeft: '8px', cursor: 'pointer' }}>
                            {(name?.length < 12) ? (name) : (name?.slice(0, 12) + "...")}
                        </span>)}
                    </div>
                {/* )} */}
            </div>
            {/* <Modal
                open={profileShow}
                onClose={() => { setShowProfile(false) }}
            >
                <div style={style}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: 'center', gap: '10px', }}>
                        <Typography id="modal-title"
                            //  variant="h6" component="h2" 
                            style={{
                                fontSize: "20px",
                                fontWeight: "700",
                                fontFamily: "Inter",
                                lineHeight: "30px",
                                textAlign: "left",

                            }}>
                            Profile
                        </Typography>
                        <button
                            onClick={() => { setShowProfile(false) }}
                            style={{
                                fontSize: "20px",
                                fontWeight: "400",
                                fontFamily: "Inter",
                                backgroundColor: 'transparent',
                                border: '0px solid transparent'
                            }}
                        >
                            <Close style={{ fontSize: "20px", }} />
                        </button>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                            gap: '16px',
                            marginTop: '20px',
                            width: '100%',
                            paddingTop: '10px',
                            borderTop: '1px solid rgba(224, 224, 224, 1)'

                        }}
                    >

                        <ZupotsuButton
                            name="Cancel"
                            variant={'outlined'}
                            padding={"10px 40px"}
                            isCustomColors={true}
                            customOutlineColor="1px solid #E0E0E0"
                            customOutlineColorOnHover="1px solid #E20B18"
                            customBgColorOnhover="#fff"
                            customBgColor="#fff"
                            customTextColorOnHover="#E20B18"
                            customTextColor="#828282"
                            handleClick={() => setShowProfile(false)}
                        />
                        <ZupotsuButton
                            name={"Submit"}
                            variant={'outlined'}
                            padding={"10px 40px"}
                            isCustomColors={true}
                            customOutlineColor="1px solid #E20B18"
                            customOutlineColorOnHover="1px solid #E20B18"
                            customBgColorOnhover="#fff"
                            customBgColor="#fff"
                            customTextColorOnHover="#E20B18"
                            customTextColor="#E20B18"
                            handleClick={() => {

                            }}
                        />
                    </div>
                </div>


            </Modal> */}
            <Logout logoutpop={logoutpop} setLogoutPopup={setLogoutPopup} />
        </div>
    );
}

export default memo(Header);