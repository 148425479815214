import { Button, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from '@mui/material';
import React, { useState } from 'react';
import { Closed, DocumentDownloadIcon, editIcon, FlipView, forward } from '../../../assets';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import pdfIcon from '../../../assets/pdf.svg'
import { useNavigate } from 'react-router-dom';
import Apis from '../../../services/apis';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import axios from 'axios';
const Chat = ({ item, setForwardOpen, setReplyOpen, setForwardingChat }: any) => {

    const [menuanchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const currentUser = localStorage.getItem("userID") || null
    const openMenu = Boolean(menuanchorEl);
    const userFromLocal = localStorage.getItem("role")?.toLowerCase();
    const isItAdmin = (userFromLocal === "admin") ? true : false;
    const isApprover = (userFromLocal === "approver") ? true : false;
    const isPublisher = (userFromLocal === "publisher") ? true : false;
    const [documentAccepted, setDocumentAccepted] = useState(item?.doc_accept)
    const [isSent, setIsSent] = useState(item?.sent_by == currentUser ? false : true)
    const deviceType: any = useNavigate()
    const apis = new Apis();
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };


    const handleDownload = async () => {
        const pdfUrl = `https://cors-anywhere.herokuapp.com/${encodeURIComponent(item?.doc)}`

        try {
            const response = await fetch(pdfUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = item?.doc_name || 'downloaded_file.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Cleanup
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const menuOptions = [
        { key: 1, name: 'Reply' },
        ...(isItAdmin || isPublisher || isApprover ? [{ key: 2, name: 'Forward' }] : []),
        { key: 3, name: 'Pin Message' },
        { key: 4, name: 'Delete' },
    ];

    const updateDocumentststus = async (status: any) => {
        try {
            const DocBody: any = {
                "id": item.id,
                "doc_accept": status,
            }

            if (status == "accepted") DocBody.accepted_by = currentUser && parseInt(currentUser)
            else DocBody.rejected_by = currentUser && parseInt(currentUser)
            const response = await apis.updateChat(item.id, DocBody);
            if (response?.data?.status == "success") {
                setDocumentAccepted(status)
            }
        } catch (addChatError) {
            console.error("Error adding chat box:", addChatError);
        }
    };

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: "row", justifyContent: isSent ? 'space-between' : "flex-end", alignItems: 'flex-start' }}>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', minWidth: '20%', maxWidth: '70%', gap: '5px', alignItems: 'flex-start' }}>
                {(isSent) ? (<div style={{ width: deviceType == "mobile" ? "15%" : 'auto', display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'flex-end', }}>
                    <div
                        onClick={() => { }}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 100,
                            width: '32px',
                            height: '32px',
                            cursor: 'pointer',
                            border: '2px solid var(--Theme-colors-border-2, rgba(229, 229, 229, 1))',
                            backgroundColor: '#D2EAF4',
                        }}
                    >
                        <span
                            style={{
                                fontFamily: 'Inter',
                                fontSize: '20px',
                                fontWeight: 500,
                                lineHeight: '28px',
                            }}
                        >
                            {item?.sent_by_user?.name?.charAt(0)}
                        </span>
                    </div>
                </div>) : (<></>)}

                {(!item?.doc) ? (<div style={{ width: deviceType == "mobile" ? "85%" : '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '5px' }}>
                    <div style={{ display: 'flex', justifyContent: !isSent ? "flex-end" : 'space-between', width: '100%' }}>
                        {(isSent) ? (<p
                            style={{
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontWeight: 500,
                                lineHeight: '16.94px',
                                margin: 0,
                            }}
                        >
                            {item?.sent_by_user?.name}
                        </p>) : (<></>)}
                        
                        <p
                            style={{
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontWeight: 500,
                                lineHeight: '16.94px',
                                margin: 0,
                            }}
                        >
                            {item?.sent_at && new Date(item.sent_at).toLocaleTimeString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true
                            }).replace(' ', '').toUpperCase()}

                        </p>
                    </div>
                    <div style={{
                        width: '100%',
                        borderRadius: '10px',
                        background: 'transparent',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}>

                        {(item?.reply_message_id) && (<div style={{
                            width: '100%',
                            borderRadius: '10px',
                            borderBottomLeftRadius: "0px",
                            borderBottomRightRadius: "0px",
                            borderTopRightRadius: isSent ? '0px' : "10px",
                            borderTopLeftRadius: isSent ? '10px' : "0px",
                            height: '30px',
                            background: 'rgba(50,50,50,0.05)',
                            display: 'flex',
                            paddingTop: "3px",
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: "flex-start",
                            marginBottom: '-10px'
                        }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: "flex-start",
                                gap: '5px'
                            }}>
                                <img src={forward} style={{ width: '15px', height: "12px", marginLeft: '5px' ,transform: "scaleX(-1)"}} />
                                <p style={{ fontSize: '9px', fontFamily: 'Inter', fontWeight: '500' }}>{item?.reply_message?.doc ? item?.reply_message?.doc_name?.slice(0, 15) : item?.reply_message?.text?.slice(0, 15)}</p>
                            </div>

                        </div>)}
                        <div style={{
                            width: '100%',
                            borderRadius: '10px',
                            borderTopRightRadius: isSent ? '0px' : "10px",
                            borderTopLeftRadius: isSent ? '10px' : "0px",
                            background: 'rgba(242, 242, 242, 1)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}>
                            {(item?.forwarded && !item?.reply_message_id) && (<p style={{ width: '100%', margin: 0, marginLeft: "20px", marginBottom: '-5px', padding: 0, fontFamily: 'Inter', fontSize: '10px', textAlign: "left" }}>Forwarded</p>)}
                            <div style={{
                                width: '80%',
                                borderRadius: '10px',
                                background: 'red',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    borderRadius: '10px',
                                    borderTopRightRadius: isSent ? '0px' : "10px",
                                    borderTopLeftRadius: isSent ? '10px' : "0px",
                                    background: 'transparent',
                                    padding: '10px',

                                }}
                            >
                                <p
                                    style={{
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        lineHeight: '16.94px',
                                        margin: 0,
                                        textAlign: 'left',
                                    }}
                                >
                                    {item?.text}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>) : (
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '5px' }}>
                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: !isSent ? "flex-end" : 'space-between', width: '100%' }}>
                            {(isSent) ? (<p
                                style={{
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    lineHeight: '16.94px',
                                    margin: 0,
                                }}
                            >
                                {item?.sent_by_user?.name}
                            </p>) : (<></>)}
                            <p
                                style={{
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    lineHeight: '16.94px',
                                    margin: 0,
                                }}
                            >
                                {item?.sent_at && new Date(item.sent_at).toLocaleTimeString('en-US', {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true
                                }).replace(' ', '').toUpperCase()}
                            </p>
                        </div>
                        <div style={{
                            width: '100%',
                            borderRadius: '10px',
                            background: 'transparent',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}>
                            {(item?.reply_message_id) && (<div style={{
                                width: '100%',
                                borderRadius: '10px',
                                borderBottomLeftRadius: "0px",
                                borderBottomRightRadius: "0px",
                                height: '30px',
                                background: 'rgba(50,50,50,0.05)',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: "flex-start",
                                paddingTop: "3px",
                                marginBottom: '-10px'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: "flex-start",
                                    gap: '5px'
                                }}>
                                    <img src={forward} style={{ width: '15px', height: "12px", marginLeft: '5px' }} />
                                    <p style={{ fontSize: '9px', fontFamily: 'Inter', fontWeight: '500' }}>{item?.reply_message?.doc ? item?.reply_message?.doc_name?.slice(0, 15) : item?.reply_message?.text?.slice(0, 15)}</p>
                                </div>
                            </div>)}

                            <div style={{
                                width: '100%',
                                borderRadius: '15px',
                                background: 'rgba(242, 242, 242, 1)',
                                height: item?.forwarded ? '70px' : "60px",
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                alignItems: 'center'
                            }}>
                                {(item?.forwarded && !item?.reply_message_id) && (<p style={{ width: '100%', margin: 0, marginLeft: "30px", marginBottom: '-5px', padding: 0, fontFamily: 'Inter', fontSize: '10px', textAlign: "left" }}>Forwarded</p>)}
                                <div
                                    style={{
                                        width: '100%',
                                        borderRadius: '15px',
                                        background: 'transparent',
                                        padding: '10px',
                                        height: '60px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: "flex-start",
                                        alignItems: 'center',
                                        maxWidth: '70%',
                                        minWidth: '20%',
                                        gap: '15px'
                                    }}>
                                        <img
                                            src={pdfIcon}
                                            style={{
                                                width: "32px",
                                                height: '32px'
                                            }}
                                        />
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '5px' }}>
                                            <p
                                                style={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                    lineHeight: '16.94px',
                                                    margin: 0,
                                                    padding: 0,
                                                    textAlign: 'left',
                                                    minWidth: '150px'
                                                }}
                                            >
                                                {item?.doc_name?.slice(0, 15)}
                                            </p>
                                            <p
                                                style={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                    lineHeight: '16.94px',
                                                    margin: 0,
                                                    padding: 0
                                                }}
                                            >
                                                {item?.doc_size}
                                            </p>
                                        </div>
                                    </div>
                                    <a href={item?.doc} target='_blank' download={item.doc}>
                                        <button
                                            onClick={() => { }}
                                            style={{
                                                width: "24px",
                                                height: '24px',
                                                color: 'rgba(41, 45, 50, 1)',
                                                cursor: 'pointer',
                                                backgroundColor: 'transparent',
                                                border: '0px solid transparent',
                                                padding: 0,
                                                margin: 0
                                            }}>
                                            <img
                                                src={DocumentDownloadIcon}
                                                style={{
                                                    width: "24px",
                                                    height: '24px',
                                                    color: 'rgba(41, 45, 50, 1)',
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        </button>
                                    </a>

                                </div>

                            </div>
                        </div>
                        {(item?.doc_type) && (<p style={{ width: '100%', margin: 0, paddingLeft: "30px", padding: 0, fontFamily: 'Inter', fontSize: '12px', textAlign: "left" }}>{item?.doc_type}</p>)}
                        {(item?.doc_type?.toLowerCase() == "proposal") && (<div style={{ width: '90%', display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'flex-start', gap: "12px" }}>
                            {(!documentAccepted) ? (<>
                                {(isSent) ? (<>
                                    <Button
                                        onClick={() => { updateDocumentststus("accepted") }}
                                        sx={{
                                            width: "74px",
                                            height: "32px",
                                            gap: "8px",
                                            borderRadius: "5px",
                                            color: '#FFF',
                                            background: "rgba(33, 150, 83, 1)",
                                            border: '0px solid #FFF',
                                            fontFamily: "Inter",
                                            fontSize: "12px",
                                            fontWeight: 600,
                                            lineHeight: "16.8px",
                                            '&:hover': {
                                                background: "rgba(33, 150, 83,0.8)",
                                            }
                                        }}
                                    >
                                        Accept
                                    </Button>
                                    <Button
                                        onClick={() => { updateDocumentststus("rejected") }}
                                        sx={{
                                            width: "74px",
                                            height: "32px",
                                            gap: "8px",
                                            color: '#FFF',
                                            borderRadius: "5px",
                                            background: "rgba(226, 11, 24, 1)",
                                            border: '0px solid #FFF',
                                            fontFamily: "Inter",
                                            fontSize: "12px",
                                            fontWeight: 600,
                                            lineHeight: "16.8px",
                                            '&:hover': {
                                                background: "rgba(226, 11, 24, 0.8)",
                                            }
                                        }}>
                                        Reject
                                    </Button>
                                </>) : (
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center', gap: "5px" }}>
                                        <AccessTimeIcon sx={{ width: '18px', height: '18px' }} />
                                        <p style={{
                                            fontFamily: "Inter",
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            color: 'rgba(0,0,0,0.8)',
                                            margin: 0,
                                            padding: 0
                                        }}>Waiting for acceptance</p>

                                    </div>
                                )}
                            </>) : (
                                <div style={{
                                    width: "89px",
                                    height: "32px",
                                    borderRadius: "5px",
                                    backgroundColor: documentAccepted?.toLowerCase() == "accepted" ? 'rgba(33, 150, 83, 0.15)' : "rgba(226, 11, 24, 0.15)",
                                    color: documentAccepted?.toLowerCase() == "accepted" ? 'rgba(33, 150, 83, 1)' : "rgba(226, 11, 24)",
                                    fontFamily: "Inter",
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center',
                                }}>
                                    {documentAccepted?.toLowerCase() == "accepted" ? "Accepted" : "Rejected"}
                                </div>
                            )}
                        </div>
                        )}
                    </div>
                )}


            </div>

            <div
                style={{
                    background: '#FFF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                onClick={handleMenuClick}
            >
                <MoreVertIcon style={{ color: '#333', width: '24px', height: '24px' }} />
            </div>

            <Menu
                anchorEl={menuanchorEl}
                open={openMenu}
                onClose={handleMenuClose}
                sx={{
                    boxShadow: '4px 4px 12px 0px rgba(0, 0, 0, 0.07)',
                    borderRadius: '10px',
                    mt: '8px',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: '-10px',
                        left: '-50px',
                        width: '0',
                        height: '0',
                        borderLeft: '6px solid transparent',
                        borderRight: '6px solid transparent',
                        borderBottom: '6px solid white',
                        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1)',
                    },
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuList sx={{ outline: 'none', borderRadius: '10px', padding: 0, margin: 0 }}>
                    {menuOptions.map((menudata) => (
                        <MenuItem
                            key={menudata.key}
                            onClick={() => {
                                if (menudata?.name == "Forward") {
                                    setForwardOpen(true)
                                    setForwardingChat(item)
                                    handleMenuClose()
                                } else if (menudata?.name == "Reply") {
                                    setReplyOpen(true)
                                    setForwardingChat(item)
                                    handleMenuClose()
                                }
                            }}
                            sx={{
                                display: 'flex',
                                gap: '10px',
                                '&:hover': {
                                    background: 'rgba(0,0,0,0.15)',
                                },
                            }}
                        >
                            <p
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    color: '#333',
                                    fontFamily: 'Inter',
                                    lineHeight: '21px',
                                    textAlign: 'left',
                                    padding: 0,
                                    margin: 0
                                }}
                            >
                                {menudata.name}
                            </p>
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </div >
    );
};

export default Chat;
