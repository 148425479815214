import { forwardRef, useRef } from "react";
import html2pdf from "html2pdf.js";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { GlobalB, zupotsuLogoFinal } from "../../assets";
import zupotsupdfbackground from '../../assets/zupotsupdfbackground.png'
import LanguageIcon from '@mui/icons-material/Language';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';


export const Download = () => {
    const style1 = {
        fontFamily: "Inter",
        fontSize: "13px",
        fontWeight: 500,
        lineHeight: "12.1px",
        textAlign: "left",
        paddingTop: '10px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between",
        gap: '30px'
    }
    const messagestyle = {
        paddingTop: '20px',
        paddingBottom: "5px",
        textAlign: 'left',
        fontFamily: "Inter",
        fontSize: "13px",
        fontWeight: 500,
        lineHeight: "21px",
        letterSpacing: " 0.01em",
        color: "rgba(0,0,0,0.7)",
        width: '100%'
    }
    const leftstyle = {
        width: '20%',
        fontFamily: 'Inter',
        fontSize: "13px",
        fontWeight: 500,
        lineHeight: "12.1px",
    }
    const rightstyle = {
        color: "rgba(0,0,0,0.7)",
        width: '80%',
        fontFamily: 'Inter',
        fontSize: "13px",
        fontWeight: 500,
        lineHeight: "12.1px",
    }
    const generatePDF = async () => {
        const doc = new jsPDF('p', 'mm', 'a4');

        // Get elements from the DOM
        const headerElement = document.getElementById('pdf-header');
        const footerElement = document.getElementById('pdf-footer');
        const contentElement = document.getElementById('pdf-content');

        // Capture the header and footer as images
        const headerCanvas = await html2canvas(headerElement);
        const headerImg = headerCanvas.toDataURL('image/png');

        const footerCanvas = await html2canvas(footerElement);
        const footerImg = footerCanvas.toDataURL('image/png');

        // Capture the main content as an image
        const contentCanvas = await html2canvas(contentElement);
        const contentImg = contentCanvas.toDataURL('image/png');

        // Get page dimensions
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        // Define positions and heights for header, content, and footer
        const headerHeight = 20;  // Adjust according to your header
        const footerHeight = 20;  // Adjust according to your footer
        const contentHeight = pageHeight - headerHeight - footerHeight;
        const contentYPosition = headerHeight + 10;  // Position after the header

        // Add the header image
        doc.addImage(headerImg, 'PNG', 10, 10, pageWidth - 20, headerHeight);

        // Add the content image
        doc.addImage(contentImg, 'PNG', 10, contentYPosition, pageWidth - 20, contentHeight - 30);  // Adjust based on content

        // Add the footer image
        doc.addImage(footerImg, 'PNG', 10, pageHeight - footerHeight, pageWidth - 20, footerHeight);

        // Add a page number in the footer
        doc.setFontSize(10);

        // Save the PDF
        doc.save('custom-pdf.pdf');
    };

    return (
        <div>
            {/* Header */}
            <div id="pdf-header" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "center", width: '100%' }}>
                <div style={{ width: '90%', display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "center", }}>
                    <span style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "12.1px",
                        textAlign: "left",
                    }}>June 24, 2024</span>
                    <img src={zupotsuLogoFinal} style={{ width: '170px', height: '80px' }} />
                </div>
            </div>

            {/* Content */}
            <div id="pdf-content" style={{
                backgroundImage: `url(${zupotsupdfbackground})`,
                backgroundSize: "100%",
                backgroundPosition: "center bottom",
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "cover",
                width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "center",
                height: '900px',
            }}>
                <div style={{
                    display: 'flex', flexDirection: 'column', justifyContent: "flex-start", alignItems: "flex-start", width: '90%',
                    height: '900px',
                }}>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center", marginTop: '30px', marginBottom: '15px', width: '100%' }}>
                        <p style={{
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "16.94px",
                            textAlign: "center",
                            textDecoration: "underline",
                            textUnderlineOffset: "5px",
                            color: "rgba(0, 0, 0, 1)"
                        }}>Sports Marketing Proposal</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: "flex-start", width: '100%' }}>
                        <ul style={{ listStyleType: 'none', padding: 0, margin: 0, width: '100%' }}>
                            <li style={style1}><span style={leftstyle}>Client: </span> <span style={rightstyle}>Navanith</span></li>
                            <li style={style1}><span style={leftstyle}>Category: </span> <span style={rightstyle}>T 20</span></li>
                            <li style={style1}><span style={leftstyle}>Agency: </span> <span style={rightstyle}>Team India</span></li>
                            <li style={style1}><span style={leftstyle}>Sports:</span> <span style={rightstyle}>Cricket</span></li>
                            <li style={style1}><span style={leftstyle}>Asset Type: </span> <span style={rightstyle}>Team</span></li>
                            <li style={style1}><span style={leftstyle}>Asset Name:  </span><span style={rightstyle}>Virat Kohli</span></li>
                            <li style={style1}><span style={leftstyle}>Opportunity: </span> <span style={rightstyle}>Official Barter</span></li>
                        </ul>
                    </div>
                    <p style={messagestyle}>A sports marketing message can include:
                        Know your audience: Consider how fans will consume your product and the different types of sports fans.
                        Engage with fans: Turn casual spectators into lifelong fans.
                        Use video content: Video content has high engagement and share rates.
                        Use integrated marketing campaigns: Integrate various marketing channels to ensure a unified brand message.
                        Form partnerships and sponsorships: Brand logos on jerseys, race cars, and venue banners are a prominent way to build brand awareness.
                        Work with sports influencers: Partnering with sports influencers can increase brand awareness, credibility, and drive sales.
                        Be active on social media: Many sports fans are already on social platforms looking for content.
                    </p>


                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: "flex-start", alignItems: "flex-start", gap: '5px', marginTop: '10px', width: '100%', }}>
                        <p style={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontWeight: 700,
                            lineHeight: "21px",
                            textAlign: "left",
                            margin: 0,
                            padding: 0
                        }}>Indicative Endorsement Details:</p>
                        <p style={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "21px",
                            textAlign: "left",
                            margin: 0,
                            padding: 0
                        }}>Zupotsu proposes a comprehensive endorsement package that includes:</p>
                        <ul style={{ listStyle: "inside", padding: '0px', paddingLeft: '15px' }}>
                            <li style={{
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "12.1px",
                                textAlign: "left",
                                paddingTop: '10px',
                            }}><span style={{ color: "rgba(0,0,0,0.7)", }}>Physical Right </span></li>
                            <li style={{
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "12.1px",
                                textAlign: "left",
                                paddingTop: '10px'
                            }}><span style={{ color: "rgba(0,0,0,0.7)", }}>Official Barter</span>
                            </li>

                        </ul>
                    </div>

                </div>
            </div>

            {/* Footer */}
            <div id="pdf-footer" style={{ width: '100%', height: '10vh', display: 'flex', flexDirection: 'column', justifyContent: "flex-start", alignItems: "center", gap: '5px' }}>
                <div style={{ width: '90%', height: '6vh', display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "flex-start", }}>
                    <div style={{ display: 'flex', height: '6vh', width: '25%', flexDirection: 'row', justifyContent: "space-evenly", alignItems: "center", borderRight: '1px solid red' }}>
                        <LanguageIcon sx={{ color: 'red', width: '20px', height: "20px" }} />
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start'
                        }}>
                            <p style={{ margin: 0, padding: 0, fontSize: '12px', fontFamily: 'Inter' }}>hello@zupotsu.com</p>
                            <p style={{ margin: 0, padding: 0, fontSize: '12px', fontFamily: 'Inter' }}>www.zupotsu.com</p>
                        </div>
                    </div>
                    <div style={{ display: 'flex', height: '6vh', width: '50%', flexDirection: 'row', justifyContent: "space-evenly", alignItems: "center", borderRight: '1px solid red' }}>
                        <PinDropOutlinedIcon sx={{ color: 'red', width: '20px', height: "20px" }} />
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                            alignItems: 'flex-start'
                        }}>

                            <p style={{ margin: 0, padding: 0, fontSize: '12px', fontFamily: 'Inter' }}>Amaltas Haat Pvt Ltd. We Work DlF, Two Horizon Centre,</p>
                            <p style={{ margin: 0, padding: 0, fontSize: '12px', fontFamily: 'Inter' }}>5th Floor, DLF Phase 5, Gurugram 122022, Haryana , India</p>
                        </div>
                    </div>
                    <div style={{ display: 'flex', height: '6vh', width: '25%', flexDirection: 'row', justifyContent: "space-evenly", alignItems: "center", }}>
                        <LocalPhoneIcon sx={{ color: 'red', width: '20px', height: "20px" }} />
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                            alignItems: 'center'
                        }}>
                            <p style={{ margin: 0, padding: 0, fontSize: '12px', fontFamily: 'Inter' }}>+91 1245025595</p>
                        </div>
                    </div>
                </div>
                <div style={{ width: '90%', height: '4vh', display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "flex-start", backgroundColor: "red" }}>

                </div>
            </div>

            {/* Button to generate PDF */}
            <button onClick={generatePDF}>Generate PDF</button>
        </div>
    );
};

